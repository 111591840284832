import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Metadata from "../components/metadata";

const NotFoundPage = () => {
    return (
        <Layout>
            <Metadata title="Not Found" description="Page not found" />
            <h1 className="title">Page not found</h1>
            <p>
                Sorry{" "}
                <span role="img" aria-label="Pensive emoji">
                    😔
                </span>{" "}
                we couldn’t find what you were looking for.
            </p>
            <p>
                <Link to="/" className="button button-lg is-link">
                    Visit the Home Page
                </Link>
            </p>
        </Layout>
    );
};

export default NotFoundPage;
